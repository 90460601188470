.swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    padding-bottom: 80px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2);
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    width: 64px;
    height: 2px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #E8E8E8;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
    
  }
  
  .swiper-pagination-bullet-active {
    color: #377DED;
    background: #377DED;
  }
