.root {
    width: 100%;
    padding: 100px 0px;
    background-image: url('assets/banner.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
}

.container {
    width: 1030px;
    margin: 0 auto;
}

.contactTitle {
    font-size: 56px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #242331;
    text-align: center;
}

.contactFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.contactFlexEn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.contactItem {
    width: 478px;
    height: 112px;
    box-shadow: 0px 18px 24px 0px rgba(181, 181, 181, 0.25);
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 30px;
}

.iconImg {
    width: 62px;
    margin-left: 25px;
    margin-right: 30px;
}

.itemTitle {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #1B2534;
}

.itemDes {
    font-size: 16px;
    color: #797979;
}

.contactForm {
    width: 100%;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0px 12px 33px 0px rgba(179, 179, 179, 0.25);
    box-sizing: border-box;
    padding: 60px 40px;
    margin-top: 60px;
}

.formFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.formTitle {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    margin-bottom: 6px;
}

.formTitleErr {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    margin-bottom: 6px;
    color: red;
}

.formInput {
    width: 450px;
    height: 60px;
    border-radius: 15px;
}

.formFlexTwo {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.checkBox {
    padding: 15px;
    border: 1px solid #D3D3D3;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.formTips {
    text-align: center;
    margin-top: 60px;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Roboto';
    color: #797979;
}

.formErrTip {
    font-size: 14px;
    font-weight: 500;
}

.formFlexThree {
    display: flex;
    justify-content: center;
}

.formBtn {
    width: 210px;
    height: 70px;
    background-color: #FFB422;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: 30px auto 0px auto;
    border-radius: 30px;
}

.formBtn:hover {
    border: none !important;
    background-color: #FFB422 !important;
    color: #fff !important;
}

.formSuccessBox {
    width: 1030px;
    height: 760px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 12px 33px 0px rgba(179, 179, 179, 0.25);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.successTipImg {
    width: 250px;
    padding: 20px;
}

.successTitle {
    font-size: 30px;
    color: #242331;
    font-weight: 500;
    font-family: 'Roboto';
    margin-top: 30px;
    margin-bottom: 20px;
}

.successSubTitle {
    font-size: 22px;
    color: #5236FF;
    font-weight: 500;
    font-family: 'Roboto';
    margin-top: 20px;
}

.successLine {
    width: 670px !important;
    min-width: 670px !important;
    margin: 40px auto 10px auto;
}

.successFont {
    font-size: 20px;
    color: #5236FF;
}

.wechatImg {
    width: 166px;
}

@media only screen and (max-width: 1200px) {
    .root {
        padding: 40px 0px;
    }

    .container {
        width: 95%;
    }

    .contactTitle {
        font-size: 30px;
    }

    .contactFlex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }

    .contactFlexEn {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .contactItem {
        width: 100%;
        max-width: 478px;
        height: auto;
        padding: 20px 10px;
        justify-content: flex-start;
        box-sizing: border-box;
        border-radius: 20px;
        margin-bottom: 10px;
    }

    .iconImg {
        width: 40px;
        margin-left: 5px;
        margin-right: 10px;
    }

    .itemTitle {
        font-size: 14px;
    }

    .itemDes {
        font-size: 13px;
    }

    .contactForm {
        width: 100%;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 20px 10px;
        margin-top: 20px;
    }

    .formFlex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0px;
    }

    .formTitle {
        font-size: 14px;
        margin-bottom: 4px;
    }

    .formTitleErr {
        font-size: 14px;
        margin-bottom: 4px;
        color: red;
    }

    .formInput {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .formFlexTwo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .checkBox {
        padding: 15px;
        border: 1px solid #D3D3D3;
        border-radius: 15px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .formTips {
        margin-top: 20px;
        font-size: 14px;
    }

    .formErrTip {
        font-size: 12px;
        font-weight: 500;
    }

    .formBtn {
        width: 210px;
        height: 40px;
        font-size: 16px;
        border-radius: 20px;
    }

    .formSuccessBox {
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 20px;
        margin-top: 20px;
    }

    .successTipImg {
        max-width: 250px;
        width: 75%;
    }

    .successTitle {
        font-size: 20px;
        margin-top: 10px;
    }

    .successSubTitle {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .successLine {
        width: 100% !important;
        min-width: 100% !important;
        margin: 10px auto;
    }

    .successFont {
        font-size: 14px;
        color: #5236FF;
    }

    .wechatImg {
        width: 150px;
    }
}

@media only screen and (max-width: 600px) {
    .root {
        background: rgba(249,251,249);
    }
}
