body {
  margin: 0;
  font-family: 'sofia-pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

title {
  font-weight: 900;
  font-style: normal;
  font-size: 80px;
  line-height: 88px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  /* text-transform: capitalize; */
  color: #333333;
}

h1, .h1 {
  font-family: 'sofia-pro';
  font-weight: 900;
  font-style: normal;
  font-size: 56px;
  line-height: 64px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.01em;
  color: #333333;
}

.mainBottom {
  font-weight: 900;
  font-style: normal;
  font-size: 56px;
  line-height: 64px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

h2 {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.01em;
  color: #333333;
}

h3 {
  font-family: 'sofia-pro';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.01em;
  color: #333333;
}


button {
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #333333;
}

p {
  margin: 0;
  display: flex;
  align-items: center;
}

p.big {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #666666;
}

p.normal {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;  
  color: #5C5E6B;
}

li.normal {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  align-items: center;
  letter-spacing: 0.04em;  
  color: #5C5E6B;
}

p.term {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;  
  color: #5C5E6B;
}

p.boldcap {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #333333;
}

p.cap {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #666666;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 1440px) {
 
}



@media screen and (max-width: 1024px) {

  title {
    font-size: 56px;
    line-height: 64px;
  }

 
  
}

@media screen and (max-width: 768px) {

  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h2 .hro {
    font-size: 32px;
    line-height: 40px;
  }
 
}

@media screen and (max-width: 414px) {
  .mainBottom {
    font-size: 32px;
    line-height: 40px;
  }
  title {
    font-size: 32px;
    line-height: 40px;
  }
  p.big {
    font-size: 20px;
    line-height: 32px;
  }
  
}

